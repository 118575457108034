import React, { Component, createRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//import './App.css';
import {
  Checkbox,
  Grid,
  Header,
  Image,
  Menu,
  Ref,
  Segment,
  Sidebar,
  Icon,
  Sticky,
  Rail,
} from "semantic-ui-react";
import Toilet from "./pages/toiletPage";
import ProgressOpen from "./components/progressBar-open";
import ProgressSuccess from "./components/progressBar-success";
import ProgressWaiting from "./components/progressBar-waiting";
import EmployeePage from "./pages/employeePage";
import TableExampleSelectableRow from "./pages/activityPage";
import StatusPage from "./pages/statusPage";
import Settings from './constans'
import Login from './components/login'
function App() {
  const contextRef = createRef();
  const segmentRef = React.useRef();
  const [visible, setVisible] = React.useState(true);

  return (
    <div className="App">
      <Router>
        <Ref innerRef={contextRef}>
          <Sticky context={contextRef}>
            <Menu icon="labeled" size="large" style={{marginLeft:"240px"}}>
              <Menu.Item
                name="sidebar"
                onClick={(e, data) => setVisible(!visible)}
              >
                <Icon name="sidebar" />
              </Menu.Item>
              <Menu.Item style={{padding:"0", left:"33%"}}>
                <img src={require('./text-logo-locatr.png')} style={{width:"auto", height:"auto", margin:"0", padding:"5px"}} />
              </Menu.Item>
            </Menu>
              
          </Sticky>
        </Ref>
        <Ref innerRef={contextRef}>
          <Sidebar.Pushable
            as={Segment.Group}
            raised
            style={{ margin: "0", transform:"none" }}
          >
            <Sticky context={contextRef}>
              <Sidebar
                as={Menu}
                animation="scale down"
                icon="labeled"
                /*onHide={() => setVisible(false)} */
                vertical
                visible={visible}
                
              >
                <Menu.Item style={{display:'inline-block', padding:'20px'}}>
                  <img src={require('./logodeneme.png')} />
                </Menu.Item>
                <Menu.Item as={Link} to="/">
                  <Icon name="home" />
                  Ana Sayfa
                </Menu.Item>
                <Menu.Item as={Link} to="/areas">
                  <Icon name="venus mars" />
                  Akıllı Tuvaletler
                </Menu.Item>
                <Menu.Item as={Link} to="/tasks">
                  <Icon name="tasks" />
                  Aktif ve Tamamlanmış Görevler
                </Menu.Item>
                <Menu.Item as={Link} to="/employee">
                  <Icon name="users" />
                  Çalışan Takip
                </Menu.Item>
                <Menu.Item as={Link} to="/profile">
                  <Icon name="user circle" />
                  Profil
                </Menu.Item>
                <Menu.Item as={Link} to="/settings">
                  <Icon name="configure" />
                  Ayarlar
                </Menu.Item>
              </Sidebar>
            </Sticky>
            <Sidebar.Pusher
              style={{
                overflow: "scroll",
                height: "100%",
                marginLeft: "240px",
              }}
            >
              <Segment>
                <Switch>
                  <Route exact path="/"> {/* Ana menu */}
                    <Login />
                  </Route>
                  <Route path="/areas"> {/* Alan Sayfası */}
                    <Toilet />
                  </Route>
                  <Route path="/devices"> {/* Cihaz Sayfası */}
                    <StatusPage />
                  </Route>
                  <Route path="/tasks"> {/* Tüm Görevler */}
                    <TableExampleSelectableRow />
                  </Route>
                  <Route path="/employee"> {/* Çalışan Sayfası */}
                    <EmployeePage />
                  </Route>
                  <Route path="/profile"> {/* Profil Sayfası */}
                    <Toilet />
                  </Route>
                  <Route path="/settings"> {/* Ayarlar Sayfası */}
                    <Settings />
                  </Route>
                  <Route path="/activity/:id"> {/* Spesifik Alanın Yapılan İşleri */}
                    <Toilet />
                  </Route>
                  <Route path="/employee/:id"> {/* Spesifik Çalışanın Görevleri */}
                    <Toilet />
                  </Route>
                  <Route path="/employee/:loc"> {/* Spesifik Çalışanın Konumları */}
                    <Toilet />
                  </Route>
                  <Route path="/area/:id" render={(props) => <StatusPage {...props}/>}/>
                   {/* Spesifik Cihazın Görevleri */}
                </Switch>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Ref>
      </Router>
    </div>
  );
}

export default App;
