import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Button, Card, Divider, Image, Placeholder, Grid, Icon } from 'semantic-ui-react'
import { URL } from '../constans';
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import socketIOClient from 'socket.io-client';

function EmployeePage() {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [active, setActive] = useState(true);
 
  useEffect(() => {
  setLoading(true);
  const socket = socketIOClient(URL);
  socket.on("status", data => {
    alert('geldi');
    axios
        .get(URL + "/api/v1/employee")
        .then((res) => {
          setEmployees(res.data.data);
          setActive(prevState => !prevState);
        })
        .catch((error) => {
          console.log(error);
        });
  });
    axios
        .get(URL + "/api/v1/employee")
        .then((res) => {
          setEmployees(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });

    setTimeout(() => {
      setLoading(false);
    }, 1500)
    return () => socket.disconnect();
  }, []);
 
    return (
      <>
        <Card.Group doubling itemsPerRow={6} stackable style={{textAlign:"center"}}>
          {_.map(employees, (employee) => (
            <Card key={employee._id}>
              {loading ? (
                <Placeholder>
                  <Placeholder.Image rectangular />
                </Placeholder>
              ) : (
                <Image src='https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs2/112692698/original/31a5d2469689575beee06ffcf4e9e76abab3abe2/logo-design-for-profile-picture-dessin-pour-photo-de-profil.png'/>
              )}

              <Card.Content>
                {loading ? (
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line length='very short' />
                      <Placeholder.Line length='medium' />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length='short' />
                    </Placeholder.Paragraph>
                  </Placeholder>
                ) : (
                  <>
                    <Card.Header>{employee.employeeName}</Card.Header>
                    <Grid>
                      <Grid.Row columns={2} divided>
                        <Grid.Column>
                    <Card.Meta>{employee.roles} {employee.employeeGender}</Card.Meta>
                      </Grid.Column>
                        <Grid.Column>
                        {active=== true ? <Icon name='circle' color='grey' /> :<Card.Description> <Icon name='circle' color='green'/> Durum bilgisi verelim soket ile</Card.Description>}
                      </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </>
                )}
              </Card.Content>

              <Card.Content extra>
                <Button as={Link} to={'/employee/' + employee._id} disabled={loading} primary>
                  Tamamlanan Görevler
                </Button>
                <Button as={Link} to={'/employee/' + employee._id} disabled={loading}>Son Konumu</Button>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </>
    )
  }

export default EmployeePage;