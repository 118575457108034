import _ from 'lodash'
import React, { useEffect, useState } from "react";
import {
  Card,
  Progress,
  Grid,
  Image,
  Item,
  Header,
  Statistic,
} from "semantic-ui-react";
import { URL, MQTT } from '../constans';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const mqtt = require('mqtt');

function StatusPage (props) {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [filter, setFilter] = useState(null);
  
    useEffect(() => {
      setLoading(true);
      const {id} = props.match.params
      
        axios
            .get(`${URL}/api/v1/area/${id}`)
            .then((res) => {
              setDevices(res.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
        setTimeout(() => {
          setLoading(false);
        }, 1500)
        
      const client = mqtt.connect(`ws://localhost:3000`);
      
     client.on('connect', function () {
       client.subscribe('outTopic', function (err) {
         
      })
      client.on('message', function (topic, message) {
        // message is Buffer
        
           console.log(message.toString())
           axios
           .get(`${URL}/api/v1/area/${id}`)
           .then((res) => {
             setDevices(res.data.data);
             if(res.status !== 304)
             toast.success("Success Notification !", {
              position: toast.POSITION.TOP_RIGHT
            });
           })
           .catch((error) => {
             console.log(error);
           });
           
         })
      })
        return () => client.end();
      }, []);
    
    return (
      <>
      <ToastContainer />
      <Grid columns={8} divided>
        <Grid.Row>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/heat.png')}
                />
                <Statistic size='tiny'>
      <Statistic.Value>
      25.6 
      </Statistic.Value>
      <Statistic.Label>Isı °C</Statistic.Label>
    </Statistic>
          </Grid.Column>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/felt.jpeg')}/>
                <Statistic size='tiny'>
      <Statistic.Value>
      27.8 
      </Statistic.Value>
      <Statistic.Label>Hissedilen °C</Statistic.Label>
      </Statistic>
          </Grid.Column>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/humidity.png')}/>
                <Statistic size='tiny'>
      <Statistic.Value>
      42.5 
      </Statistic.Value>
      <Statistic.Label>Nem %</Statistic.Label>
      </Statistic>
          </Grid.Column>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/bad.jpg')}/>
                <Statistic size='tiny'>
      <Statistic.Value>
       5
      </Statistic.Value>
      <Statistic.Label>Kötü Koku %</Statistic.Label>
      </Statistic>
          </Grid.Column>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/flame.jpg')}/>
                <Statistic size='tiny'>
      <Statistic.Value>
       0
      </Statistic.Value>
      <Statistic.Label>Alev %</Statistic.Label>
      </Statistic>
          </Grid.Column>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/dirty.png')}/>
                <Statistic size='tiny'>
      <Statistic.Value>
       20
      </Statistic.Value>
      <Statistic.Label>Kirlilik %</Statistic.Label>
      </Statistic>
          </Grid.Column>
          <Grid.Column>
          <Item.Image
                  size="tiny"
                  src={require('../images/usage.jpeg')}/>
                <Statistic size='tiny'>
      <Statistic.Value>
       212
      </Statistic.Value>
      <Statistic.Label>Kullanım</Statistic.Label>
      </Statistic>
          </Grid.Column>
          <Grid.Column>
            
            <Item.Image
                  size="tiny"
                  src={require('../images/women.jpeg')}/>
                
            
            
            <Statistic size='tiny'>
                <Statistic.Value>1.</Statistic.Value>
                <Statistic.Label>Kat</Statistic.Label>
              </Statistic>
              </Grid.Column>
        </Grid.Row>
      </Grid>
  <Grid columns={3} divided>
    <Grid.Row>
    {_.filter(devices.iot, filter!==null ? { iotName: this.state.filter}:{}).map(device => (
      <Grid.Column>
        <Item>
          <Grid columns={3} divided>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Item.Image
                  size="tiny"
                  src={require('../images/watersensor.png')}
                />
              </Grid.Column>
              
              <Grid.Column>
                <Statistic label="IOT Cihaz" value={device.iotId + "."} />
              </Grid.Column>
              <Grid.Column>
                <Statistic label="Değişen Sarf" value={device.asset} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Item.Content>
            <Item.Meta style={{ textAlign: "center" }}>
              {device.areaName}
            </Item.Meta>
            <Item.Description>
              <Progress percent={device.status} progress active indicating>
                Kalan Sarf Malzemesi
              </Progress>
            </Item.Description>
          </Item.Content>
        </Item>
      </Grid.Column>
    ))}
    </Grid.Row>
  </Grid>
  </>
 )
}
export default StatusPage;
