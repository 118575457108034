

import _ from 'lodash'
import React, { useEffect, useState } from "react";
import {
  Card,
  Progress,
  Grid,
  Image,
  Item,
  Header,
  Statistic,
  Button,
} from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Settings (props) {
    
    const [DENEME, setLoading] = useState(5);
    return (
        <Button primary onClick={(e, data) => {
            setLoading(10);
            DENEME1 = DENEME;
        }}>sd</Button>
        
        )
    }
    export default Settings;
    export const URL = 'https://locatr.loca.lt'
    export const MQTT = '0.tcp.ngrok.io:12764'
    export let DENEME1 = '5';